import HAdmin from "../Components/home_admin_component/home_admin";

const Home_Admin_Page = () => {
    return ( 
        <>
        <HAdmin/>
        </>
     );
}
 
export default Home_Admin_Page;