import Registery from "../Components/register_component/usr_register";

const Regist = () => {
    return ( 
        <>
         <Registery/>
        </>
     );
}
 
export default Regist;