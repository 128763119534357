import Ulogin from "../Components/login_component/usr_login";

const Logg = () => {
    return ( 
        <>
        <Ulogin/>
        </>
     );
}
 
export default Logg;