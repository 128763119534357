import Usr from '../Components/cust_component/usr'

const custPage = () => {
    return ( 
        <>
        <Usr/>
        </>
     );
}
 
export default custPage;